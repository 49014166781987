import React from 'react';
import Article from 'components/help/helpArticle';
import Link from 'components/ui/link';

const Content = () => {
  return (
    <div>
      <p>LiveSession script is watching for those events during user visit on your website:</p>
      <ul>
        <li>Mouse movements</li>
        <li>Mouse clicks</li>
        <li>HTML changes of your website</li>
        <li>Value changes of inputs, selects, forms</li>
        <li>Browser viewport size change</li>
        <li>Window scrolling</li>
        <li>Focusing and blurring of HTML elements</li>
        <li>Keystrokes (disabled by default)</li>
      </ul>

      <div className="alert alert-primary mb-5 mt-5" role="alert">
        By default, recording of user&apos;s keystrokes is disabled. You can read more how to turn
        it on <Link href="/help/how-to-record-keystrokes/">here</Link>.{' '}
      </div>
    </div>
  );
};

const related = [
  {
    link: '/help/how-to-install-livesession-script',
    title: 'How to install LiveSession script?',
    description: 'Check how can you install LiveSession on any website.',
  },
];

export const frontmatter = {
  metaTitle: 'How LiveSession tracking script works?',
  metaDescription: 'Understand what our tracking code tracks',
  canonical: '/help/how-livesession-script-works/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped related={related} section="Product" title={frontmatter.metaTitle} {...frontmatter} />
);
